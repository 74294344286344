import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { usePlayerStore } from './stores/PlayerStore';
import { playerService } from './api/playerService';
import { Player } from './models/Player';
import PlayerDetailModal from "./PlayerDetailModal";
import PlayerTimeline from "./PlayerTimeline";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowUp, faCircle, faArrowDown, faTimes, faThumbsUp, faThumbsDown, 
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { playerStoreInstance } from '../src/stores/PlayerStore';
import PlayerSearch from './PlayerSearch';
import ClipLoader from "react-spinners/ClipLoader";
import PlayerComparisonOverlay from './PlayerComparisonOverlay';
import { motion, useAnimation } from 'framer-motion';
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions
import { firestore } from '../src/firebase'; // Import your Firestore instance

// Move this function to the top of the file, before any component definitions
const getPositionName = (pos) => {
    // First check if it's already a string position
    if (typeof pos === 'string') {
        return pos;
    }

    // Handle numeric positions
    const positions = {
        1: 'Torwart',
        2: 'Abwehr',
        3: 'Mittelfeld',
        4: 'Sturm'
    };
    
    return positions[pos] || 'Unbekannt';
};

// Add this at the top level of your file
const fetchVotesForPlayer = async (playerId) => {
    const voteRef = doc(firestore, 'playerVotes', playerId);
    const voteDoc = await getDoc(voteRef);
    
    if (voteDoc.exists()) {
        const voteData = voteDoc.data();
        return {
            upvotes: voteData.upvotes || 0,
            downvotes: voteData.downvotes || 0
        };
    }
    return { upvotes: 0, downvotes: 0 };
};

// Helper function to get position sort order
const getPositionSortOrder = (position) => {
    const order = {
        'Torwart': 1,
        'Abwehr': 2,
        'Mittelfeld': 3,
        'Sturm': 4
    };
    return order[position] || 5;
};

// Calculate total market value
const calculateTotalMarketValue = (players) => {
    return players.reduce((total, player) => total + (player.marketValue || 0), 0);
};

// Calculate budget balance
const calculateBudgetBalance = (selectedPlayers, players, currentBudget) => {
    const selectedMarketValue = Array.from(selectedPlayers).reduce((total, playerId) => {
        const player = players.find(p => p.id === playerId);
        return total + (player?.marketValue || 0);
    }, 0);
    return currentBudget + selectedMarketValue;
};

// Calculate selected market value
const calculateSelectedMarketValue = (selectedPlayers, players) => {
    return Array.from(selectedPlayers).reduce((total, playerId) => {
        const player = players.find(p => p.id === playerId);
        return total + (player?.marketValue || 0);
    }, 0);
};

const PlayerCard = React.memo(({ player, selectedPlayers, setSelectedPlayers, votes, ...props }) => {
    // Early validation with more detailed logging
    if (!player || !player.id) {
        console.warn('Invalid player data received:', player);
        return null;
    }

  

    // Wait for position data to be available
    if (player.position === undefined) {
        console.log('Waiting for position data for player:', player.fullName);
        return null;
    }

    // Trend icon helper
    const getTrendIcon = (value) => {
        if (!value || value === 0) return <FontAwesomeIcon icon={faCircle} className="text-gray-400 ml-2" />;
        return value > 0 
            ? <FontAwesomeIcon icon={faArrowUp} className="text-green-500 ml-2" />
            : <FontAwesomeIcon icon={faArrowDown} className="text-red-500 ml-2" />;
    };

    // Keep our existing image URLs
    const playerImageUrl = `https://kickbase.b-cdn.net/pool/playersbig/${player.id}.png`;
    const teamLogoUrl = `https://kickbase.b-cdn.net/pool/teams/${player.teamId}.png`;
    const teamDummyUrl = `https://kickbase.b-cdn.net/pool/teamsdummies/${player.teamId}.png`;

    // Get status color
    const getStatusColor = (status) => {
        switch (status) {
            case 2: return 'border-2 border-yellow-500';
            case 1: return 'border-2 border-red-500';
            case 0: return 'border-2 border-indigo-500';
            default: return 'border-2 border-yellow-500';
        }
    };

    return (
        <div key={`${player.id}_${props.index}`} className="bg-gray-800 p-6 rounded-xl shadow-2xl max-w-md transition-transform relative">
            <h4 className="text-2xl font-bold text-white mb-1 absolute top-6 left-6">
                {player.fullName}
            </h4>

            <label htmlFor={`customCheckbox${player.id}`} className="flex items-center cursor-pointer absolute top-5 right-16">
                <div className="relative">
                    <input
                        id={`customCheckbox${player.id}`}
                        type="checkbox"
                        className="hidden"
                        checked={selectedPlayers.has(player.id)}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedPlayers(prev => new Set([...prev, player.id]));
                            } else {
                                setSelectedPlayers(prev => new Set([...prev].filter(id => id !== player.id)));
                            }
                        }}
                    />
                    <div className={`toggle__line w-8 h-3 ${selectedPlayers.has(player.id) ? 'bg-indigo-400' : 'bg-gray-400'} rounded-full shadow-inner`}></div>
                    <div className={`toggle__dot absolute w-5 h-5 ${selectedPlayers.has(player.id) ? 'bg-indigo-600 translate-x-full' : 'bg-white'} rounded-full shadow inset-y-0 left-0 transition-transform duration-300 ease-in-out`}></div>
                </div>
            </label>

            <div className="flex items-start space-x-4 mt-16">
                <div className={`w-32 h-32 mt-2 rounded-full overflow-hidden ${getStatusColor(player.status)}`}>
                    <img
                        src={playerImageUrl}
                        alt={player.fullName}
                        className="w-full h-full object-cover transition-opacity duration-700 ease-in-out rounded-full"
                        onLoad={(e) => {
                            e.target.style.opacity = 1;
                        }}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = teamDummyUrl;
                        }}
                    />
                </div>

                <div className="flex flex-col flex-grow space-y-2">
                    {/* Position with validation */}
                    <span className="text-sm text-gray-400 py-1 px-2 rounded-md bg-gray-700 max-w-max">
                        {getPositionName(player.position)}
                    </span>

                    {/* Stats Row */}
                    <div className="flex space-x-2">
                        <span className="text-sm text-gray-400 py-1 px-2 rounded-md bg-gray-700 flex-1">
                            <strong>Punkte:</strong> {player.totalPoints}
                        </span>
                        <span className="text-sm text-gray-400 py-1 px-2 rounded-md bg-gray-700 flex-1">
                            <strong>Schnitt:</strong> {player.averagePoints}
                        </span>
                    </div>

                    {/* Market Value */}
                    <button
                        onClick={() => { props.setSelectedPlayer(player); props.setDataModalOpen(true); }}
                        className="text-sm text-gray-400 py-1.5 px-2 rounded-md bg-[#11a0f8] hover:bg-[#0d8ad9] w-full text-center transition-colors duration-300 text-white font-semibold"
                    >
                        <strong>{player.marketValue?.toLocaleString()} €</strong>
                    </button>

                    {/* Market Stats */}
                    {player.marketStats && (
                        <div className="flex flex-col space-y-2">
                              <span className="text-sm text-gray-400 py-1 px-2 rounded-md bg-gray-700 flex justify-between items-center">
                                <strong>{player.marketStats.fourHourTrend?.toLocaleString()} €</strong>
                               {getTrendIcon(player.marketStats.fourHourTrend)}
                            </span>
                            <span className="text-sm text-gray-400 py-1 px-2 rounded-md bg-gray-700 flex justify-between items-center">
                                <strong>{player.marketStats.sevenDayTrend?.toLocaleString()} €</strong>
                                {getTrendIcon(player.marketStats.sevenDayTrend)}
                            </span>
                          
                        </div>
                    )}

                    {/* Upcoming Matches */}
                    {player.upcomingMatches && player.upcomingMatches.length > 0 && (
                        <div className="flex gap-2">
                            {player.upcomingMatches.slice(1, 6).map((match, idx) => {
                                // Determine if it's a home or away game
                                const isHome = match.t1 === player.teamId;
                                const opponentTeamId = isHome ? match.t2 : match.t1;
                                
                                return (
                                    <div key={idx} className="flex items-center bg-gray-700 p-1 rounded">
                                        {/* Show only opponent team with H/A indicator */}
                                        <span className="text-xs text-gray-400 mr-1">
                                            {isHome ? 'H' : 'A'}
                                        </span>
                                        <img 
                                            src={`https://kickbase.b-cdn.net/pool/teams/${opponentTeamId}.png`}
                                            alt="Opponent"
                                            className="w-4 h-4"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${opponentTeamId}.png`;
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>

            {/* Votes */}
            <div className="mt-4 bg-gray-700 p-2 rounded-lg flex justify-between items-center">
                <span className="text-sm font-bold text-white">Einschätzungen:</span>
                <div className="flex items-center space-x-4">
                    <span className="flex items-center">
                        <FontAwesomeIcon icon={faThumbsUp} className="text-green-500 mr-1" />
                        {votes[player.id]?.upvotes || 0}
                    </span>
                    <span className="flex items-center">
                        <FontAwesomeIcon icon={faThumbsDown} className="text-red-500 mr-1" />
                        {votes[player.id]?.downvotes || 0}
                    </span>
                </div>
            </div>
        </div>
    );
});

const TeamOverview = observer(() => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [enhancedPlayers, setEnhancedPlayers] = useState([]);
    const [sortType, setSortType] = useState('marketValue');
    const [filterPosition, setFilterPosition] = useState('All');
    const playerStore = usePlayerStore();

    useEffect(() => {
        const fetchData = async () => {
            if (!playerStore.leagueId) return;
            
            try {
                setLoading(true);
                const squadData = await playerService.getSquadPlayers(playerStore.leagueId);
                
                const enhancedPlayersPromises = squadData.it.map(async player => {
                    try {
                        const enhancedData = await playerService.getEnhancedPlayerData(
                            playerStore.leagueId,
                            player.i
                        );
                        
                        // Validate position data
                        if (enhancedData.position === undefined) {
                            console.warn('Missing position data for player:', enhancedData.fullName);
                            return null;
                        }
                        
                        return enhancedData;
                    } catch (error) {
                        console.error(`Error fetching enhanced data for player ${player.i}:`, error);
                        return null;
                    }
                });

                const players = await Promise.all(enhancedPlayersPromises);
                const validPlayers = players.filter(Boolean);
                
                // Log the valid players data
                console.log('Valid players with positions:', validPlayers.map(p => ({
                    name: p.fullName,
                    position: p.position,
                    mappedPosition: getPositionName(p.position)
                })));
                
                setEnhancedPlayers(validPlayers);
                
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [playerStore.leagueId]);

    const [votes, setVotes] = useState({});

    const [dataModalOpen, setDataModalOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [timelineModalOpen, setTimelineModalOpen] = useState(false);
    const [selectedPlayers, setSelectedPlayers] = useState(new Set());
    const [view, setView] = useState('team');
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [playerTraits, setPlayerTraits] = useState({});
    const [showFitInfo, setShowFitInfo] = useState({});
    const [compareOverlayOpen, setCompareOverlayOpen] = useState(false);
    const [currentPlayerForComparison, setCurrentPlayerForComparison] = useState(null);
    const inputRef = useRef(null); // Create a ref for the input
    const [possibleFormations, setPossibleFormations] = useState([]);
    const [estimatedPoints, setEstimatedPoints] = useState(0);

    // Update the market value calculation
    const totalMarketValue = useMemo(() => {
        return enhancedPlayers.reduce((total, player) => {
            // Use fourHourTrend from marketStats
            const fourHourTrend = player.marketStats?.fourHourTrend || 0;
            return total + fourHourTrend;
        }, 0);
    }, [enhancedPlayers]);

    const selectedMarketValue = useMemo(() => {
        if (!selectedPlayers || !enhancedPlayers) return 0;
        return Array.from(selectedPlayers).reduce((total, playerId) => {
            const player = enhancedPlayers.find(p => p.id === playerId);
            return total + (player?.marketValue || 0);
        }, 0);
    }, [selectedPlayers, enhancedPlayers]);

    const budgetBalance = useMemo(() => {
        return (playerStore.budget || 0) + (selectedMarketValue || 0);
    }, [playerStore.budget, selectedMarketValue]);

    // Safe number formatting helper
    const formatNumber = (value) => {
        return (value || 0).toLocaleString('de-DE');
    };

    // Update the market value display hook
    const useMarketValueDisplay = (value) => {
        const controls = useAnimation();
        const [displayedValue, setDisplayedValue] = useState(0);

        useEffect(() => {
            const step = Math.ceil(Math.abs(value - displayedValue) / 50);
            const interval = setInterval(() => {
                setDisplayedValue(current => {
                    if (Math.abs(value - current) <= step) {
                        clearInterval(interval);
                        return value;
                    }
                    return current + (value > current ? step : -step);
                });
            }, 20);

            return () => clearInterval(interval);
        }, [value]);

        useEffect(() => {
            if (displayedValue === value) {
                controls.start({ scale: [1, 1.1, 1] }, { duration: 0.3 });
            }
        }, [displayedValue, value, controls]);

        return { displayedMarketValue: displayedValue, controls };
    };

    const { displayedMarketValue, controls } = useMarketValueDisplay(totalMarketValue);

    const closeFooterBar = () => {
        setSelectedPlayers(new Set());
    }

    const groupedPlayers = useMemo(() => {
        if (sortType !== 'position') return [];

        const groups = enhancedPlayers.reduce((acc, player) => {
            const group = acc.find(g => g.position === player.position);
            if (group) {
                group.players.push(player);
                group.totalMarketValue += player.marketValue;
                group.totalPoints += player.totalPoints;
                group.averagePoints += player.averagePoints / group.players.length;
            } else {
                acc.push({ position: player.position, players: [player], totalMarketValue: player.marketValue, totalPoints: player.totalPoints, averagePoints: player.averagePoints });
            }
            return acc;
        }, []);

        return groups.sort((a, b) => getPositionSortOrder(a.position) - getPositionSortOrder(b.position));
    }, [enhancedPlayers, sortType]);

    const toggleFitInfo = (playerId) => {
        setShowFitInfo(prev => ({ ...prev, [playerId]: !prev[playerId] }));
    };

    const getFitColor = (percentage) => {
        if (percentage >= 70) return 'text-green-500';
        if (percentage >= 40) return 'text-yellow-500';
        return 'text-red-500';
    };

    const backgroundColor = totalMarketValue > 0 ? 'bg-green-600' : 'bg-red-600';

    const closeAllModals = () => {
        setDataModalOpen(false);
        setTimelineModalOpen(false);
        setSearchModalOpen(false);
        setCompareOverlayOpen(false);
    };

    useEffect(() => {
        if (searchModalOpen && inputRef.current) {
            inputRef.current.focus(); // Focus the input when the modal opens
        }
    }, [searchModalOpen]); // Run effect when modal open state changes

    const calculateMarketValueTrend = (player) => {
        if (!player) return "Unbekannt";
        // marketValueTrend: 1 = up, 2 = stable, 3 = down
        switch (player.marketValueTrend) {
            case 1:
                return "Steigend";
            case 2:
                return "Stabil";
            case 3:
                return "Fallend";
            default:
                return "Unbekannt";
        }
    };

    const autoSelectPlayersToSell = useCallback(() => {
    

        // Start with all players selected
        const newSelectedPlayers = new Set(enhancedPlayers.map(p => p.id));
        let currentBalance = playerStore.budget;



        // Sort players by criteria (worst performers first, so we sell them)
        const sortedPlayers = [...enhancedPlayers].sort((a, b) => {
            if (a.averagePoints !== b.averagePoints) return a.averagePoints - b.averagePoints;
            if (a.totalPoints !== b.totalPoints) return a.totalPoints - b.totalPoints;
            return (a.totalPoints / a.marketValue) - (b.totalPoints / b.marketValue);
        });

        console.log('Sorted players to potentially sell:', sortedPlayers.map(p => p.name));

        // Remove players until we have a non-negative balance or can't remove more
        for (const playerToSell of sortedPlayers) {
            if (currentBalance >= 0) {
                console.log('Positive balance reached');
                break;
            }

            if (newSelectedPlayers.size <= 11) {
                console.log('Minimum team size reached');
                break;
            }

            newSelectedPlayers.delete(playerToSell.id);
            currentBalance += playerToSell.marketValue;
            console.log(`Player sold: ${playerToSell.name}, New balance: ${currentBalance}`);

            // Check if we still have a valid selection
            const remainingPlayers = enhancedPlayers.filter(p => newSelectedPlayers.has(p.id));
            console.log('Remaining players:', remainingPlayers.map(p => p.name));
            console.log('Current position counts:', countPositions(remainingPlayers));
            
            if (!isValidSelection(remainingPlayers)) {
                console.log('Cannot sell more players without invalidating selection');
                newSelectedPlayers.add(playerToSell.id); // Add back the last player we tried to sell
                currentBalance -= playerToSell.marketValue;
                break;
            }
        }

        console.log('Final newSelectedPlayers:', newSelectedPlayers);
        console.log('Final balance:', currentBalance);
        setSelectedPlayers(newSelectedPlayers);
        updatePossibleFormations(enhancedPlayers.filter(p => newSelectedPlayers.has(p.id)));
        calculateEstimatedPoints(newSelectedPlayers);
    }, [enhancedPlayers, selectedPlayers, playerStore.budget]);

    const countPositions = (players) => {
        return players.reduce((counts, player) => {
            // Convert numeric position to string position
            const positionName = getPositionName(player.position);
            counts[positionName] = (counts[positionName] || 0) + 1;
            return counts;
        }, { Torwart: 0, Abwehr: 0, Mittelfeld: 0, Sturm: 0 });
    };

    const isValidSelection = (players) => {
        const counts = countPositions(players);
        const isValid = counts.Torwart >= 1 && 
                        counts.Abwehr >= 3 && 
                        counts.Mittelfeld >= 2 && 
                        counts.Sturm >= 1 && 
                        (counts.Abwehr + counts.Mittelfeld + counts.Sturm) >= 10;
        console.log('Is selection valid:', isValid, 'Counts:', counts);
        return isValid;
    };

    const updatePossibleFormations = (players) => {
        const counts = countPositions(players);
        
        // Log current squad composition
        console.log('Current squad composition:', {
            total: players.length,
            ...counts
        });

 
        const possible = ['3-4-3', '3-5-2', '3-6-1', '4-2-4', '4-3-3', '4-4-2', '4-5-1', '5-2-3', '5-3-2', '5-4-1']
            .filter(formation => {
                const [def, mid, fwd] = formation.split('-').map(Number);
                const hasEnoughPlayers = 
                    (counts.Abwehr + counts.Verteidiger) >= def && 
                    counts.Mittelfeld >= mid && 
                    counts.Sturm >= fwd;
                
                if (hasEnoughPlayers) {
                    console.log(`Formation ${formation} is possible`);
                }
                return hasEnoughPlayers;
            });

        setPossibleFormations(possible);
    };

    const calculateEstimatedPoints = useCallback((selectedPlayers) => {
        const playersToKeep = enhancedPlayers.filter(player => !selectedPlayers.has(player.id));
        const totalPoints = playersToKeep.reduce((sum, player) => sum + (player.averagePoints || 0), 0);
        console.log('Estimated points:', totalPoints);
        return totalPoints;
    }, [enhancedPlayers]);

    useEffect(() => {
        const points = calculateEstimatedPoints(selectedPlayers);
        setEstimatedPoints(points);
    }, [selectedPlayers, calculateEstimatedPoints]);

    useEffect(() => {
        const fetchVotesForAllPlayers = async () => {
            const votesData = {};
            for (const player of enhancedPlayers) {
                const playerVotes = await fetchVotesForPlayer(player.id);
                votesData[player.id] = playerVotes;
            }
            setVotes(votesData);
        };

        fetchVotesForAllPlayers();
    }, [enhancedPlayers]);

    useEffect(() => {
        console.log('=== TeamOverview Debug Info ===');
        console.log('Players in store:', playerStore.players);
        console.log('Market players:', playerStore.marketPlayers);
        console.log('Player data structure:', playerStore.players[0] && {
            id: playerStore.players[0].id,
            name: playerStore.players[0].name,
            firstName: playerStore.players[0].firstName,
            marketValue: playerStore.players[0].marketValue,
            position: playerStore.players[0].position,
            // Log all available fields from the new API response
            averagePoints: playerStore.players[0].averagePoints,
            totalPoints: playerStore.players[0].totalPoints,
            marketValueTrend: playerStore.players[0].marketValueTrend,
            status: playerStore.players[0].status,
            marketValueGainLoss: playerStore.players[0].marketValueGainLoss,
            sevenDayMarketValueTrend: playerStore.players[0].sevenDayMarketValueTrend,
            twentyFourHourMarketValueTrend: playerStore.players[0].twentyFourHourMarketValueTrend
        });
    }, [playerStore.players, playerStore.marketPlayers]);

    useEffect(() => {
        console.log('Players in store:', playerStore.players?.length);
        console.log('Market players:', playerStore.marketPlayers?.length);
        console.log('User players:', playerStore.userPlayers?.length);
        console.log('Sorted filtered players:', enhancedPlayers?.length);
    }, [playerStore.players, playerStore.marketPlayers, playerStore.userPlayers, enhancedPlayers]);

    useEffect(() => {
        console.log('Debug values:', {
            players: playerStore.players,
            enhancedPlayers,
            selectedPlayers,
            totalMarketValue,
            selectedMarketValue,
            budgetBalance
        });
    }, [playerStore.players, enhancedPlayers, selectedPlayers, totalMarketValue, selectedMarketValue, budgetBalance]);

    const sortedFilteredPlayers = useMemo(() => {
        if (!enhancedPlayers.length) return [];

        let filtered = enhancedPlayers;

        if (filterPosition !== 'All') {
            filtered = filtered.filter(player => 
                player.position === parseInt(filterPosition)
            );
        }

        return filtered.sort((a, b) => {
            switch (sortType) {
                case 'marketValue':
                    return (b.marketValue || 0) - (a.marketValue || 0);
                case 'marketValueIncrease':
                    return (b.marketStats?.marketValueGainLoss || 0) - (a.marketStats?.marketValueGainLoss || 0);
                case 'points':
                    return (b.totalPoints || 0) - (a.totalPoints || 0);
                case 'averagePoints':
                    return (b.averagePoints || 0) - (a.averagePoints || 0);
                case 'pointsPerEuro':
                    return (b.totalPoints / b.marketValue || 0) - (a.totalPoints / a.marketValue || 0);
                case 'valuePotential':
                    return (b.marketStats?.sevenDayTrend || 0) - (a.marketStats?.sevenDayTrend || 0);
                default:
                    return 0;
            }
        });
    }, [enhancedPlayers, filterPosition, sortType]);

    // Add these new states at the top of the component
    const [isVirtualSelling, setIsVirtualSelling] = useState(false);
    const [virtualBudget, setVirtualBudget] = useState(playerStore.budget);

    // Update virtual budget calculations
    const virtualBudgetInfo = useMemo(() => {
        if (!isVirtualSelling) return null;

        const selectedValue = Array.from(selectedPlayers).reduce((total, playerId) => {
            const player = enhancedPlayers.find(p => p.id === playerId);
            return total + (player?.marketValue || 0);
        }, 0);

        const currentTeamValue = playerStore.totalTeamValue;
        const virtualBudget = playerStore.calculateVirtualBudget(selectedValue);
        const availableInvestment = playerStore.availableInvestment;

        return {
            selectedValue,
            currentTeamValue,
            virtualBudget,
            availableInvestment
        };
    }, [isVirtualSelling, selectedPlayers, enhancedPlayers, playerStore]);

    // Add this new component for the virtual selling controls
    const VirtualSellingControls = React.memo(({ isVirtualSelling, onAutoSell }) => {
        if (!isVirtualSelling) return null;

        return (
            <div className="bg-gray-700 text-white p-4 rounded-lg shadow-md mb-4">
                
            </div>
        );
    });

    // Add this component to display remaining players info
    
    if (error) {
        return (
            <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded">
                <h2 className="text-lg font-bold mb-2">Error loading data</h2>
                <p>{error}</p>
            </div>
        );
    }

    if (!enhancedPlayers?.length) {
        console.log('No players to display');
        return (
            <div className="flex justify-center items-center min-h-screen">
                <ClipLoader size={50} color={"#123abc"} loading={true} />
            </div>
        );
    }

    const MemoizedPlayerCard = React.memo(PlayerCard, (prevProps, nextProps) => {
        return prevProps.player.id === nextProps.player.id &&
               prevProps.selectedPlayers.has(prevProps.player.id) === nextProps.selectedPlayers.has(nextProps.player.id);
    });

    const MemoizedMarketValueDisplay = React.memo(({ displayedMarketValue, controls }) => {
        const value = displayedMarketValue;
        return (
            <div className={`bg-gray-800 text-white p-3 rounded-lg shadow-md mb-4 text-center ${
                value > 0 ? 'bg-green-700' : value < 0 ? 'bg-red-700' : 'bg-gray-700'
            }`}>
                <h2 className="text-sm font-semibold">Marktwertgewinn heute</h2>
                <motion.p
                    className="text-lg font-bold mt-1"
                    animate={controls}
                >
                    {value.toLocaleString('de-DE')} €
                    {value > 0 ? (
                        <FontAwesomeIcon icon={faArrowUp} className="text-green-400 ml-2" />
                    ) : value < 0 ? (
                        <FontAwesomeIcon icon={faArrowDown} className="text-red-400 ml-2" />
                    ) : (
                        <FontAwesomeIcon icon={faCircle} className="text-gray-400 ml-2" />
                    )}
                </motion.p>
            </div>
        );
    });

    const PlayerList = React.memo(({ players, setSelectedPlayer, setTimelineModalOpen, setCompareOverlayOpen, selectedPlayers, setSelectedPlayers, playerStore, setDataModalOpen, votes }) => {
        return (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6 mt-8 items-start justify-center">
                {players.map((player, index) => {
                    
                    return (
                        <MemoizedPlayerCard
                            key={`${player.id}_${index}`}
                            player={player}
                            index={index}
                            setSelectedPlayer={setSelectedPlayer}
                            setTimelineModalOpen={setTimelineModalOpen}
                            setCompareOverlayOpen={setCompareOverlayOpen}
                            selectedPlayers={selectedPlayers}
                            setSelectedPlayers={setSelectedPlayers}
                            marketValueChange={player.marketValue - (player.price || 0)}
                            opponentTeamId={player.nextOpponent ? player.nextOpponent.teamId : null}
                            homeOrAway={player.nextOpponent ? player.nextOpponent.homeOrAway : 'Unknown'}
                            showFitInfo={showFitInfo}
                            playerStore={playerStore}
                            positionRanking={playerStore.getPositionRanking(player)}
                            marketValueTrend={calculateMarketValueTrend(player)}
                            setDataModalOpen={setDataModalOpen}
                            votes={votes}
                            fitPercentage={player.calculateFitPercentage ? player.calculateFitPercentage(playerStore) : 0}
                        />
                    );
                })}
            </div>
        );
    });


    return (
        <div className="relative">
            <header>
                <h1 className="sr-only">Kickbase Team Manager - Spielerübersicht</h1>
            </header>
            <main role="main" aria-label="Team Overview">
                {/* Filter Section */}
                <div className="bg-gray-800 text-white p-4 rounded shadow-md flex flex-row justify-between items-center mb-4">
                    <div className="flex-1 flex justify-between items-center">
                        <div className="text-left mr-4">
                            <select
                                value={sortType}
                                onChange={(e) => setSortType(e.target.value)}
                                className="w-auto p-2 border bg-gray-700 text-white focus:outline-none focus:ring-1 focus:ring-indigo-500"
                            >
                                <option value="marketValueIncrease">MW-Veränderung</option>
                                <option value="marketValue">Marktwert</option>
                                <option value="averagePoints">Punkteschnitt</option>
                                <option value="pointsPerEuro">Punkteschnitt/Euro</option>
                                <option value="valuePotential">Wertsteigerungspotenzial</option>
                                <option value="valueForMoney">Gesamtpunkte/Euro</option>
                                <option value="position">Position</option>
                            </select>
                        </div>
                        
              
                    </div>
                </div>
                <MemoizedMarketValueDisplay 
                    displayedMarketValue={displayedMarketValue || 0} 
                    controls={controls} 
                />
                <PlayerList
                    players={sortedFilteredPlayers}
                    setSelectedPlayer={setSelectedPlayer}
                    setTimelineModalOpen={setTimelineModalOpen}
                    setCompareOverlayOpen={setCompareOverlayOpen}
                    selectedPlayers={selectedPlayers}
                    setSelectedPlayers={setSelectedPlayers}
                    playerStore={playerStore}
                    setDataModalOpen={setDataModalOpen}
                    votes={votes}
                    showFitInfo={showFitInfo}
                />
                {
                    compareOverlayOpen && (
                        <PlayerComparisonOverlay
                            isOpen={compareOverlayOpen}
                            onClose={() => setCompareOverlayOpen(false)}
                            currentPlayer={currentPlayerForComparison}
                        />
                    )}
                {
                    timelineModalOpen && selectedPlayer && (
                        <Modal
                            isOpen={timelineModalOpen}
                            onRequestClose={closeAllModals} // Close modal on overlay click
                            overlayClassName="ReactModal__Overlay"
                            className="ReactModal__Content"
                        >
                            <button
                                className="absolute top-2 right-2 text-4xl text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out"
                                onClick={closeAllModals} // Close modal
                            >
                                &times;
                            </button>
                            <PlayerTimeline playerId={selectedPlayer.id} onClose={closeAllModals} />
                        </Modal>
                    )
                }

                {
                    dataModalOpen && selectedPlayer && (
                        <Modal
                            isOpen={dataModalOpen}
                            onRequestClose={closeAllModals} // Close modal on overlay click
                            overlayClassName="ReactModal__Overlay"
                            className="ReactModal__Content"
                        >
                            <button
                                className="absolute top-2 right-2 text-4xl text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out"
                                onClick={closeAllModals} // Close modal
                            >
                                &times;
                            </button>
                            <PlayerDetailModal player={selectedPlayer} onClose={closeAllModals} />
                        </Modal>
                    )
                }
                {
                    searchModalOpen && (
                        <Modal
                            isOpen={searchModalOpen}
                            onRequestClose={closeAllModals} // Close modal on overlay click
                            overlayClassName="fixed inset-0 flex items-center justify-center z-50"
                            className="bg-transparent relative" // Ensure the modal is relative
                        >
                            <button
                                className="absolute top-1 right-1 text-4xl text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out"
                                onClick={closeAllModals} // Close modal
                                style={{ zIndex: 1001 }} // Ensure it's above other content
                            >
                                &times;
                            </button>
                            <PlayerSearch inputRef={inputRef} onClose={closeAllModals} />
                        </Modal>
                    )
                }

                {loading && enhancedPlayers.length === 0 && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
                        <motion.div animate={{ scale: [1, 1.2, 1] }} transition={{ duration: 0.5, repeat: Infinity }}>
                            <ClipLoader size={50} color={"#123abc"} loading={loading} />
                        </motion.div>
                    </div>
                )}

                {/* Add Virtual Selling Controls */}
                <VirtualSellingControls 
                    isVirtualSelling={isVirtualSelling} 
                    onAutoSell={autoSelectPlayersToSell}
                />

                {selectedPlayers.size > 0 && (
                    <div className="fixed bottom-0 left-0 w-full transition-all duration-300 ease-in-out">
                        <div className={`relative mx-auto max-w-7xl p-4 rounded-t-xl shadow-lg ${
                            !isValidSelection(enhancedPlayers.filter(p => !selectedPlayers.has(p.id))) 
                                ? 'bg-gradient-to-r from-red-900/95 to-red-800/95' 
                                : 'bg-gradient-to-r from-gray-900/95 to-gray-800/95'
                        }`}>
                            {/* Close button */}
                            <button
                                onClick={() => setSelectedPlayers(new Set())}
                                className="absolute top-3 right-3 p-2 rounded-lg hover:bg-gray-700/50 transition-colors duration-200"
                                aria-label="Close selection"
                            >
                                <FontAwesomeIcon icon={faTimes} className="text-gray-400 hover:text-white" />
                            </button>

                            <div className="flex flex-col space-y-3">
                                {/* Squad Status */}
                                <div className="flex items-center">
                                    <div className="text-lg text-gray-100">
                                        <strong>{enhancedPlayers.length - selectedPlayers.size}</strong> Spieler verbleibend
                                        {!isValidSelection(enhancedPlayers.filter(p => !selectedPlayers.has(p.id))) && (
                                            <span className="ml-3 text-red-400 font-medium">
                                                ⚠️ Ungültige Mannschaftsgröße
                                            </span>
                                        )}
                                    </div>
                                </div>

                                {/* Financial Info */}
                                <div className="flex flex-wrap gap-4">
                                    <div className="px-4 py-2 bg-gray-800/40 rounded-lg">
                                        <span className="text-gray-400 text-sm">Marktwert</span>
                                        <div className="text-white font-medium">
                                            {formatNumber(virtualBudgetInfo?.selectedValue || selectedMarketValue)}€
                                        </div>
                                    </div>
                                    
                                    {isVirtualSelling && (
                                        <div className="px-4 py-2 bg-gray-800/40 rounded-lg">
                                            <span className="text-gray-400 text-sm">Teamwert</span>
                                            <div className="text-white font-medium">
                                                {formatNumber(virtualBudgetInfo?.currentTeamValue)}€
                                            </div>
                                        </div>
                                    )}
                                    
                                    <div className="px-4 py-2 bg-gray-800/40 rounded-lg">
                                        <span className="text-gray-400 text-sm">
                                            {isVirtualSelling ? 'Virtuelles Budget' : 'Kontostand'}
                                        </span>
                                        <div className={`font-medium ${
                                            (isVirtualSelling ? virtualBudgetInfo?.virtualBudget : budgetBalance) >= 0 
                                                ? 'text-green-400' 
                                                : 'text-red-400'
                                        }`}>
                                            {formatNumber(isVirtualSelling ? virtualBudgetInfo?.virtualBudget : budgetBalance)}€
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Add RemainingPlayersInfo component */}
               
            </main>
        </div>
    );

});
function getOpponentLogoURL(teamId) {
    return `/teams/${teamId}.png`;
}

export default TeamOverview;