import { apiInstance } from './instance';

const TOKEN_EXPIRY_DAYS = 3;

export const playerService = {
    _squadCache: new Map(), // { leagueId: { data, timestamp } }
    CACHE_DURATION: 5 * 60 * 1000, // 5 minutes

    _isSquadCacheValid(leagueId) {
        const cache = this._squadCache.get(leagueId);
        if (!cache) return false;
        
        const now = Date.now();
        return (now - cache.timestamp) < this.CACHE_DURATION;
    },

    async getPlayerDetails(leagueId, playerId) {
        const response = await apiInstance.get(`/leagues/${leagueId}/players/${playerId}`);
        return response.data;
    },

    async getPlayerPerformance(leagueId, playerId) {
        const response = await apiInstance.get(`/leagues/${leagueId}/players/${playerId}/performance`);
        return response.data;
    },

    async getSquadPlayers(leagueId) {
        if (this._isSquadCacheValid(leagueId)) {
            console.log('Using cached squad data for league:', leagueId);
            return this._squadCache.get(leagueId).data;
        }

        try {
            console.log('Fetching fresh squad data for league:', leagueId);
            const response = await apiInstance.get(`/leagues/${leagueId}/squad`);
            
            this._squadCache.set(leagueId, {
                data: response.data,
                timestamp: Date.now()
            });

            return response.data;
        } catch (error) {
            console.error('Error fetching squad players:', error);
            throw error;
        }
    },

    async refreshSquadPlayers(leagueId) {
        try {
            console.log('Force refreshing squad data for league:', leagueId);
            const response = await apiInstance.get(`/leagues/${leagueId}/squad`);
            
            this._squadCache.set(leagueId, {
                data: response.data,
                timestamp: Date.now()
            });

            return response.data;
        } catch (error) {
            console.error('Error refreshing squad players:', error);
            throw error;
        }
    },

    async getMarketPlayers(leagueId) {
        try {
            const response = await apiInstance.get(`/leagues/${leagueId}/market`);
            console.log('Market players:', response.data);
            return {
                items: response.data.it.map(player => ({
                    id: player.i,
                    firstName: player.fn,
                    lastName: player.n,
                    teamId: player.tid,
                    position: player.pos,
                    status: player.st,
                    marketValueTrend: player.mvt,
                    marketValue: player.mv,
                    totalPoints: player.p,
                    averagePoints: player.ap,
                    expireInSeconds: player.exs,
                    price: player.prc,
                    seller: player.u,
                    isNew: player.isn,
                    isPosLocked: player.iposl,
                    dateTime: player.dt
                }))
            };
        } catch (error) {
            console.error('Error fetching market players:', error);
            throw error;
        }
    },

    async getEnhancedPlayerData(leagueId, playerId) {
        try {
            const [details, performance, squad] = await Promise.all([
                this.getPlayerDetails(leagueId, playerId),
                this.getPlayerPerformance(leagueId, playerId),
                this.getSquadPlayers(leagueId)
            ]);

            const squadPlayer = squad.it.find(p => p.i === playerId);

            return {
                // Basic Info
                id: details.i,
                firstName: details.fn,
                lastName: details.ln,
                fullName: `${details.fn} ${details.ln}`,
                shirtNumber: details.shn,
                
                // Team Info
                teamId: details.tid,
                teamName: details.tn,
                
                // Status
                status: details.st,
                statusText: details.stxt,
                position: details.pos,
                
                // Performance
                totalPoints: details.tp,
                averagePoints: details.ap,
                goals: details.g,
                assists: details.a,
                
                // Market Values
                marketValue: details.mv,
                marketValueTrend: details.mvt,
                fourHourTrend: details.tfhmvt,
                
                // Match Data
                currentDay: details.day,
                redCards: details.r,
                yellowCards: details.y,
                
                // Performance History
                performanceHistory: details.ph,
                
                // Squad Data
                marketStats: squadPlayer ? {
                    marketValueGainLoss: squadPlayer.mvgl,
                    sevenDayTrend: squadPlayer.sdmvt,
                    fourHourTrend: squadPlayer.tfhmvt,
                    lastOwner: squadPlayer.lo,
                    lastStatus: squadPlayer.lst
                } : null,
                
                // Match Schedule
                upcomingMatches: details.mdsum,
                
                // Season Performance
                seasonPerformance: performance.it
            };
        } catch (error) {
            console.error('Error fetching enhanced player data:', error);
            throw error;
        }
    },

    async login(email, password) {
        try {
            const response = await apiInstance.post('/auth/login', { email, password });
            const { token, user } = response.data;

            // Save token with expiration timestamp
            const expirationTime = Date.now() + (TOKEN_EXPIRY_DAYS * 24 * 60 * 60 * 1000);
            localStorage.setItem('token', token);
            localStorage.setItem('token_expiry', expirationTime.toString());
            localStorage.setItem('user', JSON.stringify(user));

            return { token, user };
        } catch (error) {
            console.error('Login failed:', error);
            throw error;
        }
    },

    isTokenValid() {
        const token = localStorage.getItem('token');
        const expiryTime = localStorage.getItem('token_expiry');

        if (!token || !expiryTime) {
            return false;
        }

        return Date.now() < parseInt(expiryTime);
    },

    clearAuth() {
        localStorage.removeItem('token');
        localStorage.removeItem('token_expiry');
        localStorage.removeItem('user');
    },

    getAuthToken() {
        if (!this.isTokenValid()) {
            this.clearAuth();
            return null;
        }
        return localStorage.getItem('token');
    }
}; 