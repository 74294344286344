import React, { useState, useEffect, useMemo } from 'react';
import { getPlayerStats, getPlayerPerformance, getPlayerDetails } from './api';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { usePlayerStore } from './stores/PlayerStore';
import { faSquare, faSoccerBall, faHandshake, faChessRook, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlayerComparisonCard from './components/PlayerComparisonCard';
import PlayerComparisonOverlay from './components/PlayerComparisonOverlay';

const PlayerDetailModal = ({ player, onClose }) => {
    const [historicalData, setHistoricalData] = useState([]);
    const [timeInterval, setTimeInterval] = useState(30);
    const [show, setShow] = useState(true);
    const [showMore, setShowMore] = useState(true);
    const [sliderDays, setSliderDays] = useState(0);
    const [performanceData, setPerformanceData] = useState(null);
    const [detailedData, setDetailedData] = useState(null);
    const playerStore = usePlayerStore();
    const [showComparison, setShowComparison] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const retrievedHistoricalData = await getPlayerStats(playerStore.leagueId, player.id);
                if (retrievedHistoricalData && retrievedHistoricalData.marketValues) {
                    setHistoricalData(retrievedHistoricalData.marketValues.reverse());
                }
            } catch (error) {
                console.error("Error fetching player stats: ", error);
            }
        };

        fetchData();
    }, [player, playerStore.leagueId]);

    useEffect(() => {
        const fetchDetailedData = async () => {
            try {
                const [performance, details] = await Promise.all([
                    getPlayerPerformance(playerStore.leagueId, player.id),
                    getPlayerDetails(playerStore.leagueId, player.id)
                ]);
                setPerformanceData(performance);
                setDetailedData(details);
            } catch (error) {
                console.error('Error fetching detailed player data:', error);
            }
        };

        fetchDetailedData();
    }, [player.id, playerStore.leagueId]);

    const totalGoals = player.seasons?.reduce((acc, season) => acc + season.goals, 0);
    const totalAssists = player.seasons?.reduce((acc, season) => acc + season.assists, 0);
    const totalMatches = player.seasons?.reduce((acc, season) => acc + season.matches, 0);
    const avgGoalsPerMatch = totalMatches ? (totalGoals / totalMatches).toFixed(2) : 0;
    const avgAssistsPerMatch = totalMatches ? (totalAssists / totalMatches).toFixed(2) : 0;
    const { leagueId } = playerStore;
    const isToday = (date) => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    const filteredData = useMemo(() => {
        if (!show) return [];

        const endDateLocal = new Date();
        const startDateLocal = new Date(endDateLocal);
        startDateLocal.setDate(endDateLocal.getDate() - timeInterval);

        // Consider data from the next day if current time is after 18:00
        if (new Date() >= endDateLocal) {
            endDateLocal.setDate(endDateLocal.getDate() + 1);
        }
        const data = historicalData.filter(dataItem => {
            const date = new Date(dataItem.d);
            return date >= startDateLocal && date < endDateLocal; // Notice the "<" for the end date
        });

        return data;
    }, [historicalData, timeInterval, show]);

    const getStatusTextAndColor = (status) => {
        switch (status) {
            case 0:
                return { text: 'Topfit', color: 'green' };
            case 1:
                return { text: 'Verletzt', color: 'red' };
            case 2:
                return { text: 'Angeschlagen', color: 'yellow' };
            default:
                return { text: 'Unklar', color: 'gray' };
        }
    }

    if (!show) {
        return null;
    }

    const forecastFutureValue = (historicalData, daysIntoFuture, player) => {

        if (!historicalData || historicalData.length < 2) {
            return 0;
        }

        const yesterdayChange = historicalData[0]?.m - historicalData[1]?.m || 0;
        let forecastedValue = historicalData[0]?.m;

        for (let i = 1; i <= daysIntoFuture; i++) {
            let adjustment = 0.44 * yesterdayChange;

            // Adjust based on the day of the week
            const futureDate = new Date();
            futureDate.setDate(futureDate.getDate() + i);
            if (futureDate.getDay() === 0) adjustment *= 1.05;  // Sunday
            if (futureDate.getDay() === 5) adjustment *= 0.95;  // Friday

            // Limit extreme changes
            if (adjustment > 470000) adjustment = 470000;
            if (adjustment < -470000) adjustment = -470000;

            forecastedValue += adjustment;
        }

        // Performance Metrics:
        const recentSeason = player.seasons ? player.seasons[0] : null;
        if (recentSeason) {
            const goalsToMatchesRatio = recentSeason.goals / recentSeason.matches;
            if (goalsToMatchesRatio > 0.5) {
                forecastedValue *= 1.01;  // 1% increase for high goal scoring rate
            }
        }

        // Position Adjustment:
        if (player.position === "Stürmer") {
            forecastedValue *= 1.01;  // 1% increase for strikers
        }

        // Matches Played:
        if (player.matchesTotal > 30) {
            forecastedValue *= 1.01;  // 1% increase for players who played a lot
        }

        // Yellow Cards:
        const yellowCards = player.overallStats ? player.overallStats.yellowCards : 0;
        if (yellowCards > 10) {
            forecastedValue *= 0.99;  // 1% decrease for aggressive players
        }

        // Average Points:
        if (player.averagePoints > 40) {
            forecastedValue *= 1.01;  // 1% increase for high average points
        }

        // Status-based adjustment
        if (player.status === 1) {  // Injured
            forecastedValue *= 0.95;  // 5% decrease
        } else if (player.status === 2) {  // Slightly injured
            if (daysIntoFuture <= 4) {
                forecastedValue *= 0.98;  // 2% decrease for the first 4 days
            }
        }

        return forecastedValue;
    }


    const futureValueWithSliderDays = forecastFutureValue(historicalData, sliderDays, player.marketValue, player.status);
    const futureValueDifferenceColor = futureValueWithSliderDays >= player.marketValue ? 'text-green-500' : 'text-red-500';


    const formatCurrency = (amount) => {
        if (!amount) return '0';
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    // Function to calculate increase in value compared to previous days
    const calculateIncrease = (days) => {
        if (historicalData.length <= days) return null;
        const latestValue = historicalData[0].m;
        const previousValue = historicalData[days].m;
        return latestValue - previousValue;
    };

    const formatNumberWithDots = (number) => {
        if (!number) return '0';
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };
    const statusInfo = getStatusTextAndColor(player.status);
    const handleSliderChange = (e) => {
        setSliderDays(e.target.value);
    };


    const formatIncrease = (days) => {
        const increase = calculateIncrease(days);
        if (increase === null) return null;

        const formattedIncrease = formatCurrency(increase);
        const bgColorClassName = increase > 0 ? 'bg-green-200' : 'bg-red-300';

        return (
            <span className={`inline-block px-4 py-2 tx-black-100 m-1 rounded ${bgColorClassName}`}>
                {days} Tag{days > 1 ? 'e' : ''}: {formattedIncrease}
            </span>
        );
    };



    const calculateFutureValue = (player) => {
        const daysDifference = (new Date(player.marketValueHighDate) - new Date(player.marketValueLowDate)) / (24 * 60 * 60 * 1000);
        const dailyGrowthRate = (player.marketValueHigh - player.marketValueLow) / daysDifference;
        return player.marketValue + (dailyGrowthRate * 7);
    }
    const futureValue = calculateFutureValue(player);
    const formattedDate = new Date(player.marketValueHighDate).toLocaleDateString('de-DE');


    const getStatusClasses = (status) => {
        switch (status) {
            case 0:
                return 'text-green-600 bg-green-600';
            case 1:
                return 'text-black bg-red-600';
            case 2:
                return 'text-black bg-yellow-500';
            default:
                return 'text-gray-500 bg-gray-500';
        }
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip bg-white p-3 shadow">
                    <p className="label" style={{ color: 'black' }}>{new Date(label).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}</p>
                    <p className="intro" style={{ color: 'black' }}>
                        Marktwert: {formatNumberWithDots(payload[0].value)} Euro
                    </p>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-900 rounded-lg shadow-2xl w-full max-w-3xl max-h-[90vh] overflow-y-auto scrollbar-hide relative">
                {/* Close button */}
                <button 
                    onClick={onClose} 
                    className="absolute top-4 right-4 text-white hover:text-gray-300 focus:outline-none"
                    aria-label="Close"
                >
                    <FontAwesomeIcon icon={faTimes} className="text-2xl" />
                </button>

                <div className="p-6 text-white">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-2xl font-semibold text-center" style={{ color: '#ff4601' }}>
                            {player.name ? player.name : player.firstName + " " + player.lastName}
                        </h2>
                        <button
                            onClick={() => setShowComparison(true)}
                            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors duration-200"
                        >
                            Vergleichen
                        </button>
                    </div>

                    {/* Status Badge */}
                    <div className={`rounded-full p-2 text-white text-sm mb-4 text-center ${getStatusClasses(player.status)}`}>
                        {getStatusTextAndColor(player.status).text}
                    </div>

                    {/* Market Value Info - Always expanded */}
                    <div className="bg-gray-700 p-4 rounded shadow-xl w-full mb-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="text-center">
                                <p className="text-gray-400 font-bold">Aktueller Marktwert:</p>
                                <p className="text-2xl font-bold text-white">{player.marketValue?.toLocaleString()} €</p>
                            </div>
                        
                        </div>

                        {/* Performance Stats Grid */}
                        {detailedData && (
                            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-6">
                                <div className="bg-gray-800 p-4 rounded-lg">
                                    <h4 className="text-sm text-gray-400">Durchschnitt</h4>
                                    <p className="text-xl font-bold">{detailedData.ap}</p>
                                </div>
                                <div className="bg-gray-800 p-4 rounded-lg">
                                    <h4 className="text-sm text-gray-400">Gesamtpunkte</h4>
                                    <p className="text-xl font-bold">{detailedData.tp}</p>
                                </div>
                                <div className="bg-gray-800 p-4 rounded-lg">
                                    <h4 className="text-sm text-gray-400">Spielminuten</h4>
                                    <p className="text-xl font-bold">{Math.round(detailedData.sec / 60)}'</p>
                                </div>
                                <div className="bg-gray-800 p-4 rounded-lg">
                                    <h4 className="text-sm text-gray-400">Form</h4>
                                    <div className="flex mt-2">
                                        {detailedData.ph.slice(-5).map((perf, idx) => (
                                            <div 
                                                key={idx}
                                                className={`w-4 h-4 rounded-full mx-1 animate-fadeIn ${
                                                    perf.p > 100 ? 'bg-green-500' :
                                                    perf.p > 50 ? 'bg-yellow-500' :
                                                    'bg-red-500'
                                                }`}
                                                style={{
                                                    animation: `fadeIn 0.5s ease-in ${idx * 0.1}s forwards`,
                                                    opacity: 0
                                                }}
                                                title={`${perf.pt} Punkte`}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="bg-gray-800 p-4 rounded-lg">
                                    <h4 className="text-sm text-gray-400">Tore</h4>
                                    <p className="text-xl font-bold">{Math.round(detailedData.g)}</p>
                                </div>
                                <div className="bg-gray-800 p-4 rounded-lg">
                                    <h4 className="text-sm text-gray-400">Vorlagen</h4>
                                    <p className="text-xl font-bold">{Math.round(detailedData.a)}</p>
                                </div>
                               
                            </div>
                        )}
                    </div>
                    {/* Performance History Table */}
                    {performanceData && detailedData && (
                        <div className="mt-6">
                            <h3 className="text-xl font-bold text-white mb-4">Saisonverlauf</h3>
                            <div className="overflow-x-auto rounded-lg shadow">
                                <table className="min-w-full divide-y divide-gray-600">
                                    <thead className="bg-gray-800">
                                        <tr>
                                            <th className="px-3 py-2 text-white text-sm whitespace-nowrap">ST</th>
                                            <th className="px-3 py-2 text-white text-sm whitespace-nowrap">Pkt</th>
                                            <th className="px-3 py-2 text-white text-sm whitespace-nowrap">Min</th>
                                            <th className="px-3 py-2 text-white text-sm whitespace-nowrap">vs</th>
                                            <th className="px-3 py-2 text-white text-sm whitespace-nowrap">Erg</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-gray-700 divide-y divide-gray-600">
                                        {performanceData.it[performanceData.it.length - 1]?.ph.map((match, index) => {
                                            const isHomeTeam = match.t1 === detailedData.tid;
                                            const opponentTeamId = isHomeTeam ? match.t2 : match.t1;
                                            const result = isHomeTeam 
                                                ? `${match.t1g}:${match.t2g}`
                                                : `${match.t2g}:${match.t1g}`;

                                            return (
                                                <tr key={index} className="hover:bg-gray-600 transition-colors">
                                                    <td className="px-3 py-2 text-center text-sm text-black whitespace-nowrap">
                                                        {match.day}
                                                    </td>
                                                    <td className={`px-3 py-2 text-center text-sm font-medium whitespace-nowrap ${
                                                        match.pt > 100 ? 'text-green-400' : 
                                                        match.pt > 50 ? 'text-yellow-400' : 
                                                        'text-red-400'
                                                    }`}>
                                                        {match.p ? match.p : "-"}
                                                    </td>
                                                    <td className="px-3 py-2 text-center text-sm text-black whitespace-nowrap">
                                                        {match.mp}
                                                    </td>
                                                    <td className="px-3 py-2">
                                                        <div className="flex items-center justify-center space-x-1">
                                                            <img 
                                                                src={`https://kickbase.b-cdn.net/pool/teams/${opponentTeamId}.png`}
                                                                alt="Team"
                                                                className="w-5 h-5 rounded-full"
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${opponentTeamId}.png`;
                                                                }}
                                                            />
                                                            <span className="text-sm text-black">
                                                                {isHomeTeam ? 'H' : 'A'}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="px-3 py-2 text-center text-sm text-black whitespace-nowrap">
                                                        {result !== "undefined:undefined" ? result : "-"}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {showComparison && (
                <PlayerComparisonOverlay
                    currentPlayer={player}
                    onClose={() => setShowComparison(false)}
                />
            )}
        </div>
    );
};

export default PlayerDetailModal;